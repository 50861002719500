:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: hsl(261, 51%, 51%);
    --pink: #e83e8c;
    --pinks: #b1b6ef;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --darklight: #d1d3d4f3;
    --lightdark: #84a6bef3;
    --dark: #343a40;
    --darkinfo:rgba(46, 151, 236, 0.685);
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }
  
  body {
    margin: 0;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }



  .searchopen{
    display: none;
  }

  .search-input{
    width: 100%;
  }
  


  .card-box {
    box-shadow: 0 0 1px var(--lightdark), 0 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.65rem;
    background-color: #fff;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: .4rem;
    min-height: 50px;
    padding: .2rem;
    position: relative;
    width: 100%;
  }

  .card-box-links {
    /* box-shadow: 0 0 3px var(--lightdark), 0 1px 3px rgba(0, 0, 0, 0.2); */
    border-radius: 0.65rem;
    background-color: #fff;
    display: -ms-flexbox;
    margin-bottom: .4rem;
    width: 100%;
  }

  .card-box .card-box-icon {
    border-radius: 0.25rem;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    font-size: 0.85rem;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50px;
  }

  .card-box > div.card-icon {
    /* background-color: var(--lightdark); */
    border-radius: 0.25rem;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    /* width: 40px; */
    margin: 1px;
    padding-left: 2px;
    padding-right: 2px;
  }

  
.card-box .card-box-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.8;
  -ms-flex: 1;
  flex: 1;
  padding: 0 6px;
}

.card-box .card-box-number {
  display: block;
  margin-top: .15rem;
  font-weight: 700;
  font-size: 11px;
}

.bold {
  font-weight: 700;
  font-size: 11px;
}

.card-box .progress-description,
.card-box .card-box-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.card-box .card-box-text {
  display: block;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}
  
.info-box {
  box-shadow: 0 0 3px var(--darkinfo), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.65rem;
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: .8rem;
  min-height: 60px;
  padding: .3rem;
  position: relative;
  width: 100%;
}

.info-box:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  border-radius: 0.25rem;
  background-color:var(--lightdark);
  color: var(--white);
}

.info-box .progress {
  background-color: rgba(0, 0, 0, 0.125);
  height: 2px;
  margin: 5px 0;
}

.info-box .progress .progress-bar {
  background-color: #fff;
}

.info-box .info-box-icon {
  border-radius: 0.25rem;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.075rem;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 25px;
}

.info-box .info-box-icon > img {
  max-width: 100%;
}

.info-box .info-box-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.8;
  -ms-flex: 1;
  flex: 1;
  padding: 0 6px;
}

.info-box .info-box-number {
  display: block;
  margin-top: .25rem;
  font-weight: 700;
}

.info-box .progress-description,
.info-box .info-box-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.info-box .info-box .bg-primary,
.info-box .info-box .bg-gradient-primary {
  color: #fff;
}

.info-box .info-box .bg-primary .progress-bar,
.info-box .info-box .bg-gradient-primary .progress-bar {
  background-color: #fff;
}

.info-box .info-box .bg-secondary,
.info-box .info-box .bg-gradient-secondary {
  color: #fff;
}

.info-box .info-box .bg-secondary .progress-bar,
.info-box .info-box .bg-gradient-secondary .progress-bar {
  background-color: #fff;
}

.info-box .info-box .bg-success,
.info-box .info-box .bg-gradient-success {
  color: #fff;
}

.info-box .info-box .bg-success .progress-bar,
.info-box .info-box .bg-gradient-success .progress-bar {
  background-color: #fff;
}

.info-box .info-box .bg-info,
.info-box .info-box .bg-gradient-info {
  color: #fff;
}

.info-box .info-box .bg-info .progress-bar,
.info-box .info-box .bg-gradient-info .progress-bar {
  background-color: #fff;
}

.info-box .info-box .bg-warning,
.info-box .info-box .bg-gradient-warning {
  color: #1f2d3d;
}

.info-box .info-box .bg-warning .progress-bar,
.info-box .info-box .bg-gradient-warning .progress-bar {
  background-color: #1f2d3d;
}

.info-box .info-box .bg-danger,
.info-box .info-box .bg-gradient-danger {
  color: #fff;
}

.info-box .info-box .bg-danger .progress-bar,
.info-box .info-box .bg-gradient-danger .progress-bar {
  background-color: #fff;
}

.info-box .info-box .bg-light,
.info-box .info-box .bg-gradient-light {
  color: #1f2d3d;
}

.info-box .info-box .bg-light .progress-bar,
.info-box .info-box .bg-gradient-light .progress-bar {
  background-color: #1f2d3d;
}

.info-box .info-box .bg-dark,
.info-box .info-box .bg-gradient-dark {
  color: #fff;
}

.info-box .info-box .bg-dark .progress-bar,
.info-box .info-box .bg-gradient-dark .progress-bar {
  background-color: #fff;
}

.info-box .info-box-more {
  display: block;
}

.info-box .progress-description {
  margin: 0;
}


@media (min-width: 768px) {
  .col-xl-2 .info-box .progress-description,
  .col-lg-2 .info-box .progress-description,
  .col-md-2 .info-box .progress-description {
    display: none;
  }
  .col-xl-3 .info-box .progress-description,
  .col-lg-3 .info-box .progress-description,
  .col-md-3 .info-box .progress-description {
    display: none;
  }
}

@media (min-width: 992px) {
  .col-xl-2 .info-box .progress-description,
  .col-lg-2 .info-box .progress-description,
  .col-md-2 .info-box .progress-description {
    font-size: 0.75rem;
    display: block;
  }
  .col-xl-3 .info-box .progress-description,
  .col-lg-3 .info-box .progress-description,
  .col-md-3 .info-box .progress-description {
    font-size: 0.75rem;
    display: block;
  }
}

@media (min-width: 1200px) {
  .col-xl-2 .info-box .progress-description,
  .col-lg-2 .info-box .progress-description,
  .col-md-2 .info-box .progress-description {
    font-size: 1rem;
    display: block;
  }
  .col-xl-3 .info-box .progress-description,
  .col-lg-3 .info-box .progress-description,
  .col-md-3 .info-box .progress-description {
    font-size: 1rem;
    display: block;
  }
}

.dark-mode .info-box {
  background-color: #343a40;
  color: #fff;
}

.card-header {
  border: none;
  border-radius: 10px !important;
}


.carousel-control-next, .carousel-control-prev{
  /* background-color:var(--indigo); */
  color: var(--info);
  padding: 2px;
  border-radius: 10px;
  /* width: 40px; */
}

.carousel-control-next .carousel-control-next-icon, .carousel-control-prev .carousel-control-prev-icon{
  /* background-color: var(--indigo) !important; */
  height: 5em;
  border-radius: 10px;
}

.carousel-indicators [data-bs-target]{
  background-color:var(--indigo);
  padding: 4px;
  border-radius: 44px;
  display: none;
}

.carousel-indicators{
  margin-bottom: -20px;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.5s ease-in-out;
}

.carousel{
  width: 100%;
  /* margin-bottom: 20px; */
}

.ministry-cover{
  width: 100% !important;
}

.ministry-cover .slider-img{
  width: 100%;
  height: 100vh;
}

.ministry-cover .slider-video video{
  width: 100vw !important;
  height: 100vh;
}

.ministry-page{
  margin-top: 60px !important;
  z-index: 99;
  position: absolute;
}

.bible-cover{
  width: 100% !important;
  height: 50vh !important;
}

.bible-cover .slider-img{
  width: 100%;
  height: 50vh;
}


.opacity-none{
  opacity: .9 !important;
}

.slider{
  width: 100%;
}

.slider .slider-img{
  width: 100%;
  height:100vh;
  display: flex;
  position: absolute;
}

.slider .slider-video video{
  width: 100% !important;
  position: absolute;
}

.slider-1{
  width: 100%;
  margin-top: 10px;
}

.slider-1 .slider-1-img{
  width: 100%;
  /* height:100vh; */
  display: flex;
  position: absolute;
}

.slider-1 .slider-1-video{
  width: 100%;
  /* min-height: 100vh; */
}

.slider-1 .slider-1-video video{
  /* width: 75vw !important; */
  position: absolute;
}

.video-sermon{
  width: 100% !important;
  /* width: 200px !important; */
  height: 115px !important;
}

.audio-sermon{
  width: 100% !important;
  height: 40px !important;
}

.btn-home{
  background-color: var(--white);
  color: var(--indigo);
  border-radius: 20px 20px;
}

.btn-home:hover{
  background-color: var(--success);
  color: var(--white);
}

.img-circle{
  width: 50px;
  border-radius: 50%;
}

.ministry-img-circle{
  width: 150px;
  border-radius: 50%;
}

.user-circle{
  border-radius: 50%;
}

span.online{
  float: right;
  font-size: 4px;
  width: 6px;
  border-radius: 50%;
  padding: 0px;
  margin-right: 15px;
  margin-top: 3px;
  color: green;
  background-color:green;
 }

 span.offline{
  float: right;
  font-size: 4px;
  width: 6px;
  border-radius: 50%;
  padding: 0px;
  margin-right: 15px;
  margin-top: 3px;
  color: red;
  background-color:red;
 }

.welcome-home{
  margin-left: 25% !important;
  margin-right: 25% !important;
}

.welcome-center{
  margin-left: 20% !important;
  margin-right: 20% !important;
}

.welcome-center-1{
  margin-left: 25% !important;
  margin-right: 25% !important;
}

.welcome-in{
  border-radius: 0px 0px 100% 100% !important;
  padding-bottom: 20px;
}

/* .welcome-first:hover .welcome-one{
  background-color: transparent !important;
} */

.sdalogo{
  width: 50px;
  margin-top:-10px;
}

.texthead{
  font-size: 20px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


.img-styles{
  border: none;
  z-index: 999;
  height: 300px;
}

a.link-white{
  color: white;
}
a.link-black{
  color: black;
  font-weight: 700;
}

.truth-set-free{
  margin-right:-10px !important;
}

.events{
  background-image: url("../assets/img/cover3.jpg");
  background-size:auto;
  min-height: 100vh;
  min-height: 70vh !important;
}

.events-bg{
  background-image: url("../assets/img/primary2.jpg");
  background-size:auto;
  min-height: 100vh;
  min-height: 70vh !important;
}

.events-bible{
  background-image: url("../assets/img/coverp1.jpg");
  background-size:cover;
  min-height: 100vh;
  min-height: 70vh !important;
  margin:-10px;
}

.events-min{
  background-image: url("../assets/img/pho.jpg");
  background-size:cover;
  min-height: 100vh;
}

.events-container{
  margin-left: 10% !important;
  margin-right: 10% !important;
}

.events-div-h{
  min-height: 120px !important;
}

.events-div-h img{
  height: 120px !important;
}

/* .events-div-h img{
  position: absolute;
  height: 120px !important;
  margin-top: -25px !important;
  z-index: -9999999999;
} */

.border-left{
  border-left: 1px solid;
}

.forum-div-h{
  min-height: 90px !important;
}


.forum-div-h img{
  width: 180px;
  height: 90px !important;
}

.forum-div-h .slider-img{
  width: 100%;
  height:100vh;
  display: flex;
  position: absolute;
}

.events-center{
  margin: auto;
}

.events-date{
  width: 120px;
  margin: auto;
  padding: 10px;
  margin-top: 10px;
}

.events-date p{
  font-size: 30px;
  font-weight:700;
  color: var(--white) !important;
}

.events-date-day{
  font-size: 40px !important;
}

.events-date-month{
  font-size: 20px !important;
  margin-top: -15px;
}

.img-events-time{
  /* width: 20px; */
  border-radius: 50%;
  padding: 30px;
  margin: -30px;
  margin-left: -60px !important;
}

.img-events-venue{
  width: 100px;
  /* height: 100px; */
  border-radius: 50%;
  padding: 30px;
  margin: -20px;
  /* margin-left: -60px !important; */
}

.events-event{
  font-size: 10px;
  margin-left: -20px;
  font-weight: 900;
}

.font-bold-700{
  font-weight: 700 !important;
}

.footer-gray{
  margin-left: -12px !important;
  margin-right: -12px !important;
  margin-bottom: -12px !important;
}

.beliefs-p{
  font-size: 3.3em;
}

.border-bottom-warning{
  text-align: center;
  margin: auto;
  width: 50px;
  padding-bottom: 10px;
  border-bottom: 5px solid var(--yellow);
}

.user-div{
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 40px;
  border-radius: 50%;
  font-size: 20px;
  margin: auto;
}

.texthead-title{
  font-size: 50px;
  font-weight: 700;
  width: 400px;
  padding-top: 15px !important;
}

.texthead-title-center{
  text-align: center;
  margin: auto !important;
}

.texthead-title-right{
  margin-left: auto !important;
}

.texthead-title-left{
  margin-right: auto !important;
}

.ministryheader-title-center{
  font-size: 50px;
  font-weight: 700;
  width: 40vw;
  margin: auto !important;
  padding-top: 15px !important;
}

.welcome-verse{
  padding-left:50px !important;
  margin-top: 10px !important;
}

.welcome-verse h1{
  font-size: 40px;
}

/* .slick-slide > div{
  margin: 0 10px;
} */

/* .events-center-time{
  margin: auto !important;
} */

.bg-opacity-6{
  opacity: 0.9;
}

.bg-opacity{
  opacity: 0.8;
}

/* a.link-image{
  margin: 0px !important;
} */


.search{
  width: 35vw;
}

.search1{
  width: 35vw;
}

main.paddingtop{
  padding-top: 75px !important;
}

.monthchanger{
  display: flex;
  width: 100%;
  justify-content: space-between ;
}

.monthchanger .monthchanger-prev{
  max-height: 35px;
  flex: 1;
  text-align: center;
  background-color:var(--darklight);
  border-radius: 10px;
}

.monthchanger .monthchanger-next{
  max-height: 35px;
  flex: 1;
  text-align: center;
  background-color:var(--darklight);
  border-radius: 10px;
}

.monthchanger .monthchanger-date{
  flex: 20;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  background-color:var(--light);
  color: var(--info);
  border-radius: 10px;
}

.monthchanger .monthchanger-date:hover{
  opacity: .6;
  cursor: pointer;
}

.monthchanger .monthchanger-date.monthchanger-date-show >{
  padding: 10px;
  justify-content: space-between ;
}

.monthchanger .monthchanger-date-list .monthchanger-date-item:hover >{
  opacity: .6;
  cursor: pointer;
  background-color:var(--darklight);
}

span.title-sm{
  display: inline-block;
  color: #007bff;
}

span.title-text-right{
  float: right;
}

.selwaterbill{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  text-align: center;
}
/* .selwaterbill >{
  width: 100%;
  padding: 0;
  margin: 0;
}  */

.tableinfo{
  overflow-y: auto;
  height: calc(70vh + 2.33rem);
}

.tableinfo thead th{
  position: sticky;
  top: 0;
  background-color: var(--primary);
}

.tableinfo thead th:nth-child(1){
  position: sticky;
  left: 0;
  z-index: 1;
}

.tableinfo thead th:nth-child(2){
  position: sticky;
  left: 0;
  z-index: 1;
}

.tableinfo tbody th{
  position: sticky;
  left: 0;
  background-color: inherit;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

table{
  border-collapse: collapse;
  width: 100%;
}

.housedetails{
  overflow-y: auto;
  height: calc(17rem);
}

.wrapper .main-search {
  top: 1.5rem;
  z-index: -99999999999 !important;
}

.search-wrapper{
  padding: 6px;
  margin: 3px 3px ;
  box-shadow: 3px 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  overflow-y: auto;
  height: 90vh;
}

.search-welcome{
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}

.search-results{
  display: flex;
  flex-direction:row;
}

.search-results-div{
  flex: auto;
  margin: 2px;
  padding: 2px;
}

.reassignlbl{
  margin: 2px !important;
  font-size: 10px !important
}

.reassigntxt{
  font-size: 10px !important
}

.reassignlbl:hover{
  cursor: pointer;
  opacity: .7;
}

.assignlbl{
  margin: 1px !important;
}

.assignlbl:hover{
  cursor: pointer;
  opacity: .7;
}


div.hidden-search{
  display: flex !important;
}

ul.hidden-search{
  display: none !important;
}


@media (max-width: 968px) {
  .bible-cover{
    width: 100% !important;
    height: 30vh !important;
  }
  
  .bible-cover .slider-img{
    width: 100%;
    height: 30vh;
  }
}
@media (max-width: 768px) {
  div.hidden-search{
    display: none !important;
  }

  ul.hidden-search{
    display: flex !important;
  }


  span.title-sm{
    display: block;
  }

  .search-results{
    flex-direction:column;
  }

  .search-welcome{
    min-height: 40vh;
  }


  .dash-smalllink{
    font-size: 10px !important;
  }

  .welcome-home{
    margin-left: 17% !important;
    margin-right: 17% !important;
  }

  .img-circle{
    width: 30px;
    border-radius: 50%;
  }

  .events-container{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .welcome-one{
    padding: 5px !important;
  }

  .beliefs-p{
    font-size: 1.3em;
  }
  
  .sdalogo{
    width: 20px;
    margin-top:-60px;
  }

  .text-sda{
    margin-top: -30px !important;
  }

  .texthead{
    font-size: 10px !important;
  }

  .texthead-title{
    font-size: 14px;
    font-weight: 700;
    padding-top: 35px !important;
    padding-left: 12px !important;
  }

  /* .texthead-title-center{
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    margin: auto !important;
    padding-top: 50px !important;
    padding-left: 30px !important;
  } */

  .texthead-title-center{
    text-align: center;
    margin: auto !important;
    padding-top: 50px !important;
    padding-left: 30px !important;
  }
  
  .texthead-title-right{
    text-align: right;
    margin-left: auto !important;
    padding-top: 50px !important;
    padding-left: 30px !important;
  }
  
  .texthead-title-left{
    text-align: left;
    margin-right: auto !important;
    padding-top: 50px !important;
    padding-left: 30px !important;
  }

  .ministryheader-title-center{
    font-size: 20px;
    font-weight: 700;
    width: 80vw;
    /* text-align: center; */
    margin: auto !important;
    padding-top: 15px !important;
  }

  .welcome-verse{
    padding-left:30px !important;
    margin-top: -50px !important;
  }

  .welcome-verse h1{
    font-size: 18px;
  }

  .welcome-verse h2{
    font-size: 11px !important;
    margin-bottom: 3px;
    font-weight: 700;
  }

  .welcome-center{
    margin-left: 5% !important;
    margin-right: 5% !important;
  }

  .toppedmenu {
    width:50px;
    border-radius: 0px 10px 10px 0px;
  }

  .toppedmenu ul li a i{
    font-size: 20px;
    padding: 3px;
    margin-right: 10px;
  }

  .toppedcontainer {
    margin-left: 50px;
  }

  .toppedmenu li.side-menu {
    display: block;
  }

  .toppedmenu:hover {
    width:200px;
  }

  .toppedmenu.active {
    width:200px;
  }

  .toppedmenu.active li.side-menu {
    margin-left: 60px;
    width: 100%;
  }

  .toppedcontainer.active {
    margin-left: 190px;
  }

  .ministry-cover .slider-img{
    width: 100%;
    height: 130vh;
  }
  
  .ministry-cover .slider-video video{
    width: 100vw !important;
    height: 130vh;
  }

/* 
  .ministry-cover .slider-video video{
    width: 100vw !important;
    height: 130vh;
  } */

  .bible-cover{
    width: 100% !important;
    height: 10vh !important;
  }
  
  .bible-cover .slider-img{
    width: 100%;
    height: 10vh;
  }
  
}




